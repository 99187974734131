import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getAllPlans: any = createAsyncThunk('getAllPlans', async () => {
    const plans = await axios.get('user/subscriptions/plans');
    return plans.data;
});

export type PlanDto = {
    id: number;
    name: string;
    description: string;
    price: number;
    note: string;
    type: number;
};

interface PlansState {
    allPlans: PlanDto[];
    errorMessage: string;
    successMessage: string;
    isLoading: boolean;
}

const initialState: PlansState = {
    allPlans: [],
    errorMessage: '',
    successMessage: '',
    isLoading: false,
};

const returnErrorState = (state: PlansState, action: any) => {
    state.isLoading = false;
    state.successMessage = '';
    state.errorMessage = action.error.message;
    return state;
};

const returnPendingState = (state: PlansState) => {
    state.isLoading = true;
    return state;
};

const allPlansReducer = createSlice({
    name: 'plans',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllPlans.fulfilled, (state, action) => {
            state.allPlans = action.payload || [];
            state.isLoading = false;
            state.successMessage = 'Plans fetched success';
            state.errorMessage = '';
            return state;
        });
        builder.addCase(getAllPlans.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getAllPlans.rejected, (state, action) => {
            return returnErrorState(state, action);
        });
    },
});

export default allPlansReducer.reducer;
