import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface Attendee {
    id: number;
    user_id: number | null;
    event_id: number | null;
    bootcamp_id: number | null;
    connected_datetime: Date | null;
    subscribtion_datetime: Date;
    updated_date: Date;
    user: {
        first_name: string;
        last_name: string;
    };
}

interface AttendeesState {
    data: Attendee[];
    errorMessage: string;
    successMessage: string;
    isLoading: boolean;
}
const initialState: AttendeesState = {
    data: [],
    errorMessage: '',
    successMessage: '',
    isLoading: false,
};

export const getAttendeesByEventId = createAsyncThunk('getAttendeesByEventId', async (id: number) => {
    const attendees = await axios.get(`events/attendees/?s={"event_id":${id}}`);
    return attendees.data;
});

export const getAttendeesByBootcampId = createAsyncThunk(
    'getAttendeesByBootcampId',
    async (params: { id: number; is_paid: boolean }) => {
        const attendees = await axios.get(
            `events/attendees/?s={"bootcamp_id":${params.id}, "is_paid": ${params.is_paid}}`,
        );
        return attendees.data;
    },
);

export const addStudent = createAsyncThunk('addStudent', async (params: any) => {
    const attendees = await axios.post(`events/attendees/`, params);
    return attendees.data;
});

export const removeStudent = createAsyncThunk('removeStudent', async (id: number) => {
    const attendees = await axios.delete(`events/attendees/${id}`);
    if (attendees.status === 200) {
        return { attendee_id: id };
    }
    return { attendee_id: 0 };
});

const returnErrorState = (state: AttendeesState, action: any) => {
    state.isLoading = false;
    state.successMessage = '';
    state.errorMessage = action.error.message;
    return state;
};

const returnPendingState = (state: AttendeesState) => {
    state.isLoading = true;
    return state;
};

const returnSuccessState = (state: AttendeesState, action: any) => {
    state.data = action.payload.data;
    state.isLoading = false;
    state.successMessage = 'Attendees fetched success';
    state.errorMessage = '';
    return state;
};

const attendeesReducer = createSlice({
    name: 'attendees',
    initialState,
    reducers: {
        saveAttendees: (state, action) => {
            return returnSuccessState(state, action);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAttendeesByEventId.fulfilled, (state, action) => {
            return returnSuccessState(state, action);
        });
        builder.addCase(getAttendeesByEventId.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getAttendeesByEventId.rejected, (state, action) => {
            return returnErrorState(state, action);
        });

        builder.addCase(getAttendeesByBootcampId.fulfilled, (state, action) => {
            return returnSuccessState(state, action);
        });
        builder.addCase(getAttendeesByBootcampId.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getAttendeesByBootcampId.rejected, (state, action) => {
            return returnErrorState(state, action);
        });

        builder.addCase(addStudent.fulfilled, (state, action) => {
            if (action.payload.id) {
                const found = state.data.find((item) => item.id === action.payload.id);
                if (!found) {
                    state.data.push(action.payload);
                }
            }
            state.isLoading = false;
            state.successMessage = 'Student added success';
            state.errorMessage = '';
            return state;
        });
        builder.addCase(addStudent.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(addStudent.rejected, (state, action) => {
            return returnErrorState(state, action);
        });

        builder.addCase(removeStudent.fulfilled, (state, action) => {
            if (action.payload.attendee_id) {
                state.data = state.data.filter((item) => item.id !== action.payload.attendee_id);
            }
            state.isLoading = false;
            state.successMessage = 'Student remove success';
            state.errorMessage = '';
            return state;
        });
        builder.addCase(removeStudent.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(removeStudent.rejected, (state, action) => {
            return returnErrorState(state, action);
        });
    },
});

export const { saveAttendees } = attendeesReducer.actions;
export default attendeesReducer.reducer;
