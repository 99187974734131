import { createSlice, isPending } from '@reduxjs/toolkit';

import { cancelBootcampBySlug, getBootcampBySlug, updateBootcampBySlug } from './actions';

import { initialState } from './constants/initialState';

const bootcampDetailsReducer = createSlice({
    name: 'bootcampDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBootcampBySlug.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.resultText = action.error.message || 'Bootcamp fetch error';
            return state;
        });
        builder.addCase(getBootcampBySlug.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.resultText = 'Bootcamp fetch success';
            state.bootcamp = {
                ...action.payload,
                price: parseFloat(action.payload.price),
                number_of_seats: parseInt(action.payload.number_of_seats, 10),
                audience_level_id: parseInt(action.payload.audience_level_id, 10),
                sponsored: action.payload.sponsored_event
                    ? {
                          ...action.payload.sponsored_event,
                          type: action.payload.sponsored_event.type.toString(),
                      }
                    : {
                          id: 0,
                          type: '0',
                          company_name: '',
                          company_url: '',
                      },
            };
            return state;
        });

        builder.addCase(updateBootcampBySlug.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.resultText = action.error.message || 'Bootcamp update error';
            return state;
        });
        builder.addCase(updateBootcampBySlug.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.resultText = 'Bootcamp update success';
            state.bootcamp = {
                ...action.payload,
                price: parseFloat(action.payload.price),
                number_of_seats: parseInt(action.payload.number_of_seats, 10),
                audience_level_id: parseInt(action.payload.audience_level_id, 10),
                sponsored: action.payload.sponsored_event
                    ? {
                          ...action.payload.sponsored_event,
                          type: action.payload.sponsored_event.type.toString(),
                      }
                    : {
                          id: 0,
                          type: '0',
                          company_name: '',
                          company_url: '',
                      },
            };
            return state;
        });

        builder.addCase(cancelBootcampBySlug.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.resultText = action.error.message || 'Bootcamp cancel error';
            return state;
        });
        builder.addCase(cancelBootcampBySlug.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.resultText = 'Bootcamp cancel success';
            state.bootcamp = {
                ...action.payload,
                price: parseFloat(action.payload.price),
                number_of_seats: parseInt(action.payload.number_of_seats, 10),
                audience_level_id: parseInt(action.payload.audience_level_id, 10),
            };
            return state;
        });

        builder.addMatcher(isPending(getBootcampBySlug, updateBootcampBySlug, cancelBootcampBySlug), (state) => {
            state.isLoading = true;
            state.isError = false;
            state.resultText = '';
            return state;
        });
    },
});

export default bootcampDetailsReducer.reducer;
