import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { dollarsToCents } from '../../../../shared/utils/moneyUtils';
import { BootcampPaymentDetailsState, BootcampState } from '../types';

export const getBootcampBySlug = createAsyncThunk('getBootcampsBySlug', async (slug: string) => {
    const bootcamp = await axios.get(`events/bootcamps/${slug}?join[0]=plans&join[1]=sponsored_event`);
    return bootcamp.data;
});

export const updateBootcampBySlug = createAsyncThunk('updateBootcamBySlug', async (data: BootcampState) => {
    data.events = data.events.map((item) => {
        if (item.id && typeof item.id === 'string') {
            delete item.id; // delete IDs of newly created sessions
        }
        return item;
    });
    if (data.price === 0) {
        data.price = undefined;
    }
    const sponsoredEvent =
        data.sponsored.type !== '0'
            ? {
                  ...data.sponsored,
                  type: Number.parseInt(data.sponsored.type, 10),
              }
            : { type: 0 };

    const bootcamp = await axios.patch(`events/bootcamps/${data.slug}`, {
        ...data,
        syllabus: data.syllabus,
        plans: data.plans ? data.plans.map((id: number) => ({ id })) : undefined,
        sponsored: sponsoredEvent,
    });

    return bootcamp.data;
});

export const updateBootcampPaymentDetails: any = createAsyncThunk(
    'updateBootcampPaymentDetails',
    async (data: BootcampPaymentDetailsState) => {
        let payload = data;
        if (data.compensation) {
            const cents = dollarsToCents(parseFloat(data.compensation.toString()));
            payload = {
                ...data,
                compensation: cents,
            };
        }
        await axios.put(`events/bootcamps/paymentDetails?slug=${data.slug}`, payload);
    },
);

export const publishBootcamp = createAsyncThunk('publishBootcamp', async (data: BootcampPaymentDetailsState) => {
    await axios.post(`events/bootcamps/acceptReview?slug=${data.slug}`);
});

export const rejectBootcamp = createAsyncThunk('rejectBootcamp', async (data: BootcampPaymentDetailsState) => {
    await axios.post(`events/bootcamps/rejectReview?slug=${data.slug}`);
});

export const cancelBootcampBySlug = createAsyncThunk('cancelBootcampBySlug', async (slug: string) => {
    const response = await axios.post(`events/bootcamps/cancelBootcamp?slug=${slug}`);
    return response.data;
});

export const deleteBootcampBySlug: any = createAsyncThunk('deleteBootcampBySlug', async (slug: string) => {
    return axios.delete(`events/bootcamps/${slug}`);
});

export const createPrivateBootcamp: any = createAsyncThunk(
    'createPrivateBootcamp',
    async (data: BootcampState, { rejectWithValue }) => {
        try {
            return await axios.post('events/bootcamps/', {
                ...data,
                events: data.events.map((item) => ({ ...item, id: undefined })),
                syllabus: data.syllabus,
                plans: data.plans ? data.plans.map((id: number) => ({ id })) : undefined,
                sponsored:
                    data.sponsored.type !== '0'
                        ? {
                              ...data.sponsored,
                              type: Number.parseInt(data.sponsored.type, 10),
                          }
                        : { type: 0 },
                price: undefined,
            });
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const publishBootcampBySlug: any = createAsyncThunk('publishBootcampBySlug', async (slug: string) => {
    return axios.post(`events/bootcamps/publish?slug=${slug}`);
});

export const markAsCompensated: any = createAsyncThunk('markAsCompensated', async (slug: string) => {
    return axios.post(`payment-processor/stripe-processor/Bootcamp/payout/${slug}`);
});

export const saveBootcampAsCourse: any = createAsyncThunk(
    'saveBootcampAsCourse',
    async (data: { bootcampId: number; price: number }) => {
        return axios.post(`events/courses`, {
            bootcamp_id: data.bootcampId,
            price: dollarsToCents(data.price),
        });
    },
);

export const disableCourse: any = createAsyncThunk('disableCourse', async (bootcamp_id: number) => {
    return axios.delete(`events/courses/${bootcamp_id}`);
});
