import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { dollarsToCents } from '../../shared/utils/moneyUtils';
import { showSnackbar } from './snackbar';

export const getAllEvents = createAsyncThunk('getAllEvents', async () => {
    const events = await axios.get('events/events?filter=event_type||$eq||Webinar');
    return events.data;
});

export const getEventBySlug = createAsyncThunk('getEventBySlug', async (slug: string) => {
    const event = await axios.get(`events/events/${slug}`);
    return event.data;
});

export const getWebinarBySlug: any = createAsyncThunk('getWebinarBySlug', async (slug: string) => {
    const event = await axios.post('events/events/statistics', { slug });
    return event.data;
});

export const cancelWebinarBySlug: any = createAsyncThunk('cancelWebinarBySlug', async (slug: string, { dispatch }) => {
    const response = await axios.post('events/events/cancelWebinar', { slug });
    if (response && response.status === 201) {
        dispatch(showSnackbar({ message: 'Webinar successfully canceled', severity: 'success' }));
    } else {
        dispatch(showSnackbar({ message: response.statusText, severity: 'error' }));
    }
});

export const rejectEvent = createAsyncThunk('rejectEvent', async (slug: string, { dispatch }) => {
    const response = await axios.post(`events/events/rejevtReview?slug=${slug}`);
    if (response && response.status === 201) {
        dispatch(showSnackbar({ message: 'Webinar successfully rejected', severity: 'success' }));
    } else {
        dispatch(showSnackbar({ message: response.statusText, severity: 'error' }));
    }
});

export const publishEvent = createAsyncThunk('publishEvent', async (slug: string, { dispatch }) => {
    const response = await axios.post(`events/events/acceptReview?slug=${slug}`);
    if (response && response.status === 201) {
        dispatch(showSnackbar({ message: 'Webinar successfully published', severity: 'success' }));
    } else {
        dispatch(showSnackbar({ message: response.statusText, severity: 'error' }));
    }
});

export const updateEventBySlug: any = createAsyncThunk('updateEventBySlug', async (params: any) => {
    return axios.patch(`events/events/${params.slug}`, params);
});

export const deleteEventBySlug: any = createAsyncThunk('deleteEventBySlug', async (slug: string, { dispatch }) => {
    const response = await axios.delete(`events/events/${slug}`);
    if (response && response.status === 200) {
        dispatch(showSnackbar({ message: 'Event successfully deleted', severity: 'success' }));
    } else {
        dispatch(showSnackbar({ message: response.statusText, severity: 'error' }));
    }
});

export const updateCompensationBySlug = createAsyncThunk(
    'updateCompensationBySlug',
    async (params: any, { dispatch }) => {
        let { compensation } = params;
        if (params.compensation) {
            compensation = dollarsToCents(parseFloat(params.compensation));
        }

        const response = await axios.put(`events/events/paymentDetails?slug=${params.slug}`, {
            compensation,
            clearing_date: params.clearing_date,
        });
        if (response && response.status === 200) {
            dispatch(showSnackbar({ message: 'Compensation successfully set', severity: 'success' }));
        } else {
            dispatch(showSnackbar({ message: response.statusText, severity: 'error' }));
        }
    },
);

export const markAsCompensated = createAsyncThunk('markAsCompensated', async (params: any, { dispatch }) => {
    try {
        const response = await axios.post(`payment-processor/stripe-processor/${params.type}/payout/${params.slug}`);
        if (response && response.status === 201) {
            dispatch(showSnackbar({ message: 'Event successfully marked as paid', severity: 'success' }));
        } else {
            dispatch(showSnackbar({ message: response.statusText, severity: 'error' }));
        }
    } catch (error: any) {
        dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
    }
});

type AllEvents = {
    data: any;
    count: number;
    total: number;
    page: number;
    pageCount: number;
};

interface EventsState {
    events: AllEvents;
    selectedEvent: any;
    errorMessage: string;
    successMessage: string;
    isLoading: boolean;
}

const initialState: EventsState = {
    events: {
        data: [],
        count: 0,
        total: 0,
        page: 0,
        pageCount: 0,
    },
    selectedEvent: undefined,
    errorMessage: '',
    successMessage: '',
    isLoading: false,
};

const returnErrorState = (state: EventsState, action: any) => {
    state.isLoading = false;
    state.successMessage = '';
    state.errorMessage = action.error.message;
    return state;
};

const returnPendingState = (state: EventsState) => {
    state.isLoading = true;
    return state;
};

const returnSuccessState = (state: EventsState, action: any) => {
    state.isLoading = false;
    state.errorMessage = '';
    return state;
};

const allEventsReducer = createSlice({
    name: 'events',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllEvents.fulfilled, (state, action) => {
            state.events = action.payload;
            state.successMessage = 'All Events fetch success';
            return returnSuccessState(state, action);
        });
        builder.addCase(getAllEvents.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getAllEvents.rejected, (state, action) => {
            return returnErrorState(state, action);
        });

        builder.addCase(getEventBySlug.fulfilled, (state, action) => {
            state.selectedEvent = action.payload;
            state.successMessage = 'Event fetch success';
            return returnSuccessState(state, action);
        });
        builder.addCase(getEventBySlug.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getEventBySlug.rejected, (state, action) => {
            return returnErrorState(state, action);
        });
    },
});

export default allEventsReducer.reducer;
