import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getAllWebinars: any = createAsyncThunk('getAllWebinars', async () => {
    const webinars = await axios.get('events/public?join[0]=event.instructor&join[1]=plans');
    return webinars.data;
});

export const getDraftEvents: any = createAsyncThunk('getDraftEvents', async () => {
    const events = await axios.get('events/events?filter[0]=status||$eq||1&filter[1]=event_type||$eq||Webinar');
    return events.data;
});

export const getOnReviewEvents: any = createAsyncThunk('getOnReviewEvents', async () => {
    const events = await axios.get('events/events?filter[0]=status||$eq||2&filter[1]=event_type||$eq||Webinar');
    return events.data;
});

export const getWebinarByEventSlug = createAsyncThunk('getWebinarByEventSlug', async (slug: string) => {
    const webinar = await axios.get(
        `events/public?s={"event.slug": "${slug}"}&join[0]=event.instructor&join[1]=plans&join[2]=sponsored_event`,
    );
    return webinar.data;
});

export const updateWebinarPlansAndSponsored: any = createAsyncThunk(
    'updateWebinarPlansAndSponsored',
    async (params: any) => {
        return axios.patch(`events/public/${params.id}`, {
            plans: params.planIds.map((id: number) => ({ id })),
            sponsored: {
                ...params.sponsored,
                type: Number.parseInt(params.sponsored.type, 10),
            },
        });
    },
);

export type SponsoredEvent = {
    id: number;
    type: string;
    company_name: string;
    company_url: string;
};

type AllWebinars = {
    data: any;
    count: number;
    total: number;
    page: number;
    pageCount: number;
};

interface WebinarsState {
    webinars: AllWebinars;
    draftEvents: AllWebinars;
    onReviewEvents: AllWebinars;
    selectedWebinar: any;
    errorMessage: string;
    successMessage: string;
    isLoading: boolean;
}

const initialState: WebinarsState = {
    webinars: {
        data: [],
        count: 0,
        total: 0,
        page: 0,
        pageCount: 0,
    },
    draftEvents: {
        data: [],
        count: 0,
        total: 0,
        page: 0,
        pageCount: 0,
    },
    onReviewEvents: {
        data: [],
        count: 0,
        total: 0,
        page: 0,
        pageCount: 0,
    },
    selectedWebinar: undefined,
    errorMessage: '',
    successMessage: '',
    isLoading: false,
};

const returnErrorState = (state: WebinarsState, action: any) => {
    state.isLoading = false;
    state.successMessage = '';
    state.errorMessage = action.error.message;
    return state;
};

const returnPendingState = (state: WebinarsState) => {
    state.isLoading = true;
    return state;
};

const returnSuccessState = (state: WebinarsState, action: any) => {
    state.isLoading = false;
    state.errorMessage = '';
    return state;
};

const webinarsReducer = createSlice({
    name: 'webinars',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllWebinars.fulfilled, (state, action) => {
            state.webinars = action.payload;
            state.successMessage = 'Webinars fetched success';
            return returnSuccessState(state, action);
        });
        builder.addCase(getAllWebinars.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getAllWebinars.rejected, (state, action) => {
            return returnErrorState(state, action);
        });

        builder.addCase(getDraftEvents.fulfilled, (state, action) => {
            state.draftEvents = action.payload;
            state.successMessage = 'Draft Events fetch success';
            return returnSuccessState(state, action);
        });
        builder.addCase(getOnReviewEvents.fulfilled, (state, action) => {
            state.onReviewEvents = action.payload;
            state.successMessage = 'On Review Events fetch success';
        });
        builder.addCase(getDraftEvents.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getDraftEvents.rejected, (state, action) => {
            return returnErrorState(state, action);
        });

        builder.addCase(getWebinarByEventSlug.fulfilled, (state, action) => {
            if (!action.payload.data?.length) {
                return returnSuccessState(state, action);
            }
            state.selectedWebinar = action.payload?.data[0];
            state.successMessage = 'Webinar by Slug fetch success';
            return returnSuccessState(state, action);
        });
        builder.addCase(getWebinarByEventSlug.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getWebinarByEventSlug.rejected, (state, action) => {
            return returnErrorState(state, action);
        });
    },
});

export default webinarsReducer.reducer;
