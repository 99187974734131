import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

export const getAllApplications: any = createAsyncThunk('getAllApplications', async () => {
    const applications = await axios.get('instructor-applications/applications');

    return applications.data;
});

export const setApplicationStatus: any = createAsyncThunk('setApplicationStatus', async (params) => {
    // waiting for backend API
});

interface ApplicationsState {
    data: any;
    count: number;
    total: number;
    page: number;
    pageCount: number;
}

const initialState: ApplicationsState = {
    data: [],
    count: 0,
    total: 0,
    page: 0,
    pageCount: 0,
};

const allApplicationsReducer = createSlice({
    name: 'applications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllApplications.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export default allApplicationsReducer.reducer;
