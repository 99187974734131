import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

type Feedback = {
    id: number;
    user_id: number;
    event_id: number;
    feedback: string;
    rating: number;
    intructor_rating: number;
    created_date: Date;
    user: {
        first_name: string;
        last_name: string;
        email: string;
    };
    event: {
        title: string;
    };
};

export const getFeedbacksByEventId = createAsyncThunk('getFeedbacksByEventId', async (id: number) => {
    const feedbacks = await axios.get(`events/feedback?s={"event_id":${id}}`);
    return feedbacks.data.data;
});

export const getFeedbacksByBootcampId = createAsyncThunk('getFeedbacksByBootcampId', async (id: number) => {
    const feedbacks = await axios.get(`events/feedback?s={"bootcamp_id":${id}}`);
    return feedbacks.data.data;
});

const returnErrorState = (state: FeedbackState, action: any) => {
    state.isLoading = false;
    state.successMessage = '';
    state.errorMessage = action.error.message;
    return state;
};

interface FeedbackState {
    data: Feedback[];
    errorMessage: string;
    successMessage: string;
    isLoading: boolean;
}
const initialState: FeedbackState = {
    data: [],
    errorMessage: '',
    successMessage: '',
    isLoading: false,
};

const returnSuccessState = (state: FeedbackState, action: any) => {
    state.data = action.payload;
    state.isLoading = false;
    state.successMessage = 'Feedbacks fetch success';
    state.errorMessage = '';
    return state;
};
const returnPendingState = (state: FeedbackState) => {
    state.isLoading = true;
    return state;
};

const feedbacksReducer = createSlice({
    name: 'feedbacks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFeedbacksByEventId.fulfilled, (state, action) => {
            return returnSuccessState(state, action);
        });
        builder.addCase(getFeedbacksByEventId.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getFeedbacksByEventId.rejected, (state, action) => {
            return returnErrorState(state, action);
        });

        builder.addCase(getFeedbacksByBootcampId.fulfilled, (state, action) => {
            return returnSuccessState(state, action);
        });
        builder.addCase(getFeedbacksByBootcampId.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getFeedbacksByBootcampId.rejected, (state, action) => {
            return returnErrorState(state, action);
        });
    },
});

export default feedbacksReducer.reducer;
