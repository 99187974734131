import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export type ClearingCompensationPerMonth = {
    month: number;
    sum_of_compensation: number;
};

export const getClearingCompensationsStats = createAsyncThunk('getClearingCompensationsStats', async (year: number) => {
    const response = await axios.get(`admin/payouts/clearing-stats?year=${year}`);
    return response.data;
});

export const updateClearingDate = createAsyncThunk(
    'updateClearingDate',
    async (params: { date: string; eventId: number; eventType: 'Bootcamp' | 'Webinar' }) => {
        await axios.patch(`admin/payouts/${params.eventType}/${params.eventId}/clearing-date`, {
            clearing_date: params.date,
        });
    },
);

export const getPayouts = createAsyncThunk(
    'getPayouts',
    async (payload: { month: string; year: string }, { rejectWithValue }) => {
        try {
            const { month, year } = payload;
            const response = await axios.get('admin/payouts', {
                params: {
                    month,
                    year,
                },
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

export interface PayoutsState {
    clearingStats: ClearingCompensationPerMonth[];
}

const initialState: PayoutsState = {
    clearingStats: [],
};

const payoutsReducer = createSlice({
    name: 'payouts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getClearingCompensationsStats.fulfilled, (state, action) => {
            state.clearingStats = action.payload;
            return state;
        });
    },
});

export default payoutsReducer.reducer;
