import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showSnackbar } from './snackbar';

const showErrorMessage = (error: any, dispatch: any) => {
    dispatch(
        showSnackbar({
            message: Array.isArray(error) ? error.join('. ') : error,
            severity: 'error',
        }),
    );
};

export const getUserById: any = createAsyncThunk('getUserById', async (id) => {
    const users = await axios.get(`admin/users/${id}`);
    return users.data;
});

export const getUserEnrollments: any = createAsyncThunk('getUserEnrollments', async (id) => {
    const resp = await axios.get(`admin/users/enrolledEvents?userId=${id}`);
    return resp.data;
});

const SERVER_ERROR = 'Error: server denied operation';
export const createUser: any = createAsyncThunk('createUser', async (data, { dispatch }) => {
    const response = await axios({
        method: 'post',
        url: `admin/users`,
        data,
    }).catch(() => dispatch(showSnackbar({ message: SERVER_ERROR, severity: 'error' })));
    if (response && response.statusText === 'OK') {
        dispatch(showSnackbar({ message: 'User successfully created', severity: 'success' }));
    } else {
        dispatch(showSnackbar({ message: SERVER_ERROR, severity: 'error' }));
    }
    console.log('createUser:', response);
});

export const updateUser: any = createAsyncThunk('updateUser', async (params: any, { dispatch }) => {
    try {
        const response = await axios({
            method: 'patch',
            url: `admin/users/${params.id}`,
            data: params.data,
        });
        if (response && response.status === 200) {
            return dispatch(showSnackbar({ message: 'User successfully updated', severity: 'success' }));
        }
        throw new Error(response.data?.message || response.statusText);
    } catch (err: any) {
        const errorMessage = err.response?.data?.message || err.message;
        showErrorMessage(errorMessage, dispatch);
        throw err;
    }
});

export const deleteUser: any = createAsyncThunk('deleteUser', async (id, { dispatch }) => {
    try {
        const response = await axios.delete(`admin/users/${id}`);
        if (response && response.status === 200) {
            return dispatch(showSnackbar({ message: 'User successfully deleted', severity: 'success' }));
        }
        throw new Error(response.data?.message || response.statusText);
    } catch (err: any) {
        const errorMessage = err.response?.data?.message || err.message;
        showErrorMessage(errorMessage, dispatch);
        throw err;
    }
});

export const blockUser: any = createAsyncThunk('blockUser', async (id, { dispatch }) => {
    try {
        const response = await axios.patch(`admin/users/${id}/block`);
        if (response && response.status) {
            return dispatch(showSnackbar({ message: 'User successfully blocked', severity: 'success' }));
        }
        throw new Error(response.data?.message || response.statusText);
    } catch (err: any) {
        const errorMessage = err.response?.data?.message || err.message;
        showErrorMessage(errorMessage, dispatch);
        throw err;
    }
});

export const unblockUser: any = createAsyncThunk('unblockUser', async (id, { dispatch }) => {
    try {
        const response = await axios.patch(`admin/users/${id}/unblock`);
        if (response && response.status) {
            return dispatch(showSnackbar({ message: 'User successfully unblocked', severity: 'success' }));
        }
        throw new Error(response.data?.message || response.statusText);
    } catch (err: any) {
        const errorMessage = err.response?.data?.message || err.message;
        showErrorMessage(errorMessage, dispatch);
        throw err;
    }
});

export const updateUserSubPlans: any = createAsyncThunk('updatedUserSubPlans', async (params: any, { dispatch }) => {
    try {
        const response = await axios({
            method: 'patch',
            url: `admin/users/${params.id}/updateSubPlans`,
            data: params.data,
        });
    } catch (err: any) {
        const errorMessage = err.response?.data.message || err.message;
        showErrorMessage(errorMessage, dispatch);
        throw err;
    }
});

export const createSubscription: any = createAsyncThunk('createSubscription', async (params: any, { dispatch }) => {
    try {
        const response = await axios({
            method: 'post',
            url: `admin/users/${params.user_id}/subscription`,
            data: {
                plan_id: params.plan_id,
                expiration_date: params.expiration_date,
            },
        });
        if (response) {
            dispatch(showSnackbar({ message: 'Subscription created', severity: 'success' }));
        }
    } catch (err: any) {
        const errorMessage = err.response?.data.message || err.message;
        showErrorMessage(errorMessage, dispatch);
        throw err;
    }
});

export const removeSubscription: any = createAsyncThunk('removeSubscription', async (params: any, { dispatch }) => {
    try {
        const response = await axios({
            method: 'delete',
            url: `admin/users/subscription/${params.subscription_id}`,
        });
        if (response) {
            dispatch(showSnackbar({ message: 'Subscription removed', severity: 'success' }));
        }
    } catch (err: any) {
        const errorMessage = err.response?.data.message || err.message;
        showErrorMessage(errorMessage, dispatch);
        throw err;
    }
});

interface userDetailsViewState {
    userData: any;
    userEnrollments: any;
}

const initialState: userDetailsViewState = {
    userData: undefined,
    userEnrollments: null,
};

const userDetailsViewReducer = createSlice({
    name: 'userDetailsView',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserById.fulfilled, (state, action) => {
            return {
                ...state,
                userData: action.payload,
            };
        });

        builder.addCase(getUserEnrollments.fulfilled, (state, action) => {
            return {
                ...state,
                userEnrollments: action.payload,
            };
        });
    },
});

export default userDetailsViewReducer.reducer;
