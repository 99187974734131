import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeSnackbar, showSnackbar } from '../store/snackbar';

const useSnackbar = () => {
    const dispatch = useDispatch();
    const { opened, message, severity } = useSelector((state) => ({
        opened: state.snackbar.opened,
        message: state.snackbar.message,
        severity: state.snackbar.severity,
    }));

    return {
        opened,
        message,
        severity,
        showSnackbar: useCallback((data) => dispatch(showSnackbar(data)), [dispatch]),
        closeSnackbar: useCallback(() => dispatch(closeSnackbar()), [dispatch]),
    };
};

export default useSnackbar;
