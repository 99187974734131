/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { CircularProgress } from '@material-ui/core';
import React from 'react';

const sleep = (m: number) => new Promise((r) => setTimeout(r, m));

type componentType = React.ComponentClass<any> | null;

interface IAsyncState {
    component: componentType;
}

type LoaderProps = {
    minHeight: string;
};

const Loader = ({ minHeight }: LoaderProps) => {
    return (
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', minHeight }}>
            <CircularProgress color="secondary" />
        </div>
    );
};

const asyncComponent = (importComponent: any, loaderProps: LoaderProps = { minHeight: '100vh' }) => {
    class AsyncComponent extends React.Component<any, IAsyncState> {
        constructor(props: any) {
            super(props);

            this.state = {
                component: null,
            };
        }

        async componentDidMount() {
            await sleep(150);

            const { default: component } = await importComponent();

            this.setState({
                component,
            });
        }

        render() {
            const C: componentType = this.state.component;

            return C ? <C {...this.props} /> : <Loader {...loaderProps} />;
        }
    }

    return AsyncComponent;
};

export default asyncComponent;
