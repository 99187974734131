import React from 'react';

import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    headerRoot: {
        height: '60px',
        backgroundColor: theme.palette.info.light,
        color: theme.palette.primary.contrastText,
        width: '100%',
        padding: '0 100px',
        display: 'flex',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerContent: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    grow: {
        flexGrow: 1,
    },
}));

const Header: React.FC = ({ children }) => {
    const classes = useStyles();

    return (
        <AppBar position="static" color="primary">
            <Toolbar>
                <Typography variant="h6">Securzy</Typography>
                <div className={classes.grow} />
                {children}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
