export const getToken = (): string | null => {
    return window.localStorage.getItem('access_token');
};

export const setToken = (idToken: string): void => {
    return window.localStorage.setItem('access_token', idToken);
};

export const setRefreshToken = (refresh_token: string): void => {
    return window.localStorage.setItem('refresh_token', refresh_token);
};

export const getRefreshToken = (): string | null => {
    return window.localStorage.getItem('refresh_token');
};

export const deleteToken = (): void => {
    return window.localStorage.removeItem('access_token');
};

export const setLocalStorageItem = (key: string, value: unknown): void => {
    return window.localStorage.setItem(key, `${value}`);
};

export const getLocalStorageItem = (key: string): string | null => {
    return window.localStorage.getItem(key);
};

export const deleteLocalStorageItem = (key: string): void => {
    return window.localStorage.removeItem(key);
};
