import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import async from './components/Async';

import ConnectedSnackbar from './components/ConnectedSnackbar';
import Login from './features/pages/Login';
import './App.scss';
import useAppAuth0 from './features/hooks/useAppAuth0';

const AdminPanel = async(() => import('./features/pages/AdminPanel'));

const App = () => {
    useAppAuth0();
    return (
        <>
            <Switch>
                <Route exact path="/" component={() => <Redirect to="/login" />} />
                <Route path="/login" component={Login} />
                <Route path="/admin-panel" component={AdminPanel} />
            </Switch>
            <ConnectedSnackbar />
        </>
    );
};

export default App;
