import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { showSnackbar } from './snackbar';

const createPostDataFromObject = (dataObject: any) => {
    const bodyFormData = new FormData();
    Object.entries(dataObject).forEach((obj) => {
        const key = obj[0];
        const value = obj[1];
        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                if (typeof item === 'object') {
                    bodyFormData.append(`${key}[${index}][type]`, String(item.type));
                    bodyFormData.append(`${key}[${index}][value]`, String(item.value));
                } else {
                    bodyFormData.append(`${key}[${index}]`, String(item));
                }
            });
            if (value.length === 0) {
                bodyFormData.append(`${key}[0]`, '');
            }
        } else {
            bodyFormData.append(key, String(value));
        }
    });
    return bodyFormData;
};

export const getAppById: any = createAsyncThunk('getAppById', async (id) => {
    const app = await axios.get(`instructor-applications/applications/${id}`);

    return app.data;
});

const showErrorMessage = (error: any, dispatch: any) => {
    dispatch(
        showSnackbar({
            message: Array.isArray(error) ? error.join('. ') : error,
            severity: 'error',
        }),
    );
};

export const makeAnInstructorAndPublish: any = createAsyncThunk(
    'makeAnInstructorAndPublish',
    async (user_id: number, { dispatch }) => {
        try {
            const response = await axios.post('instructor-applications/instructors/startinstructor', { user_id });
            if (response && response.status === 201) {
                return dispatch(showSnackbar({ message: 'Instructor successfully published', severity: 'success' }));
            }
            throw new Error(response.data?.message || response.statusText);
        } catch (err: any) {
            const errorMessage = err.response?.data?.message || err.message;
            showErrorMessage(errorMessage, dispatch);
            throw err;
        }
    },
);

export const createApp: any = createAsyncThunk('createApp', async (data: any, { dispatch }) => {
    try {
        const response = await axios({
            method: 'post',
            url: `instructor-applications/applications`,
            data: createPostDataFromObject(data),
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response && response.status === 201) {
            return dispatch(showSnackbar({ message: 'App successfully created', severity: 'success' }));
        }
        throw new Error(response.data?.message || response.statusText);
    } catch (err: any) {
        const errorMessage = err.response?.data?.message || err.message;
        showErrorMessage(errorMessage, dispatch);
        throw err;
    }
});

export const updateApp: any = createAsyncThunk('updateApp', async (params: any, { dispatch }) => {
    try {
        const response = await axios({
            method: 'patch',
            url: `instructor-applications/applications/${params.id}`,
            data: createPostDataFromObject(params.data),
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response && response.status === 200) {
            return dispatch(showSnackbar({ message: 'App successfully updated', severity: 'success' }));
        }
        throw new Error(response.data?.message || response.statusText);
    } catch (err: any) {
        const errorMessage = err.response?.data?.message || err.message;
        showErrorMessage(errorMessage, dispatch);
        throw err;
    }
});

interface appDetailsViewState {
    appData: any;
}

const initialState: appDetailsViewState = {
    appData: undefined,
};

const appDetailsViewReducer = createSlice({
    name: 'appDetailsView',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAppById.fulfilled, (state, action) => {
            return {
                ...state,
                appData: action.payload,
            };
        });
    },
});

export default appDetailsViewReducer.reducer;
