/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit';

import authReducer from '../../features/store/auth';
import refData from '../../features/store/refData';
import users from '../../features/store/users';
import userDetailsView from '../../features/store/userDetailsView';
import snackbar from '../../features/store/snackbar';
import applications from '../../features/store/applications';
import appDetailsView from '../../features/store/appDetailsView';
import events from '../../features/store/events';
import instructors from '../../features/store/instructors';
import attendees from '../../features/store/attendees';
import feedbacks from '../../features/store/feedbacks';
import bootcamps from '../../features/store/bootcamps';
import bootcampDetails from '../../features/store/bootcampDetails/bootcampDetails';
import certificates from '../../features/store/certificates';
import certificateDetails from '../../features/store/certificateDetails';
import bootcampLeads from '../../features/store/bootcamp-leads';
import webinars from '../../features/store/webinars';
import plans from '../../features/store/plans';
import domains from '../../features/store/domains';
import trainings from '../../features/store/trainings';
import instructorFeedback from '../../features/store/instructorsFeedback';
import payouts from '../../features/store/payouts';

const rootReducer = () =>
    combineReducers({
        auth: authReducer,
        refData,
        users,
        userDetailsView,
        snackbar,
        applications,
        appDetailsView,
        events,
        instructors,
        attendees,
        feedbacks,
        bootcamps,
        bootcampDetails,
        certificates,
        certificateDetails,
        bootcampLeads,
        webinars,
        plans,
        domains,
        trainings,
        instructorFeedback,
        payouts,
    });

export default rootReducer;
