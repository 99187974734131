import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { deleteToken, setRefreshToken, setToken } from '../../utils/localStorage';

interface AuthState {
    isAuth: boolean;
    loading: boolean;
    errorMessage: string;
    user: User | null | undefined;
    token: string;
    isChatAuth: boolean;
}
interface User {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    is_confirmed: number;
    roles: readonly string[];
    cometchat_id: string;
}

export const logOutBy: any = createAction('logOutBy');
export const setUserChatInfo: any = createAction('setUserChatInfo');
export const resetErrorMessageBy: any = createAction('resetErrorMessageBy');

export const registrationBy: any = createAsyncThunk(
    'registrationBy',
    async (data: { email: string; password: string; first_name: string; last_name: string }) => {
        let responseData: any = {
            errorMessage: '',
        };

        await axios
            .post('api/auth/register', data, { responseType: 'text' })
            .then((response) => {
                responseData = response;
                responseData.isAuth = false;
            })
            .catch((error) => {
                const errorsList = error.response.data.errors;

                // eslint-disable-next-line guard-for-in,no-restricted-syntax
                for (const errorKey in errorsList) {
                    responseData.errorMessage = [
                        ...responseData.errorMessage,
                        errorsList[errorKey].errors[0].errorMessage,
                    ];
                }

                responseData.isAuth = false;
            });

        return responseData;
    },
);

export const loginBy: any = createAsyncThunk('loginBy', async (data: { email: string; password: string }) => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .post('token/auth/login', data, { responseType: 'text' })
        .then((response) => {
            responseData = response;
            setToken(response.data.token);
            setRefreshToken(response.data.refresh_token);
            // setLocalStorageItem('user', JSON.stringify(response.data.user));
        })
        .catch((error) => {
            responseData.errorMessage = error.response.data.title;
            responseData.isAuth = false;
        });

    return responseData;
});

export const refreshTokenAction = createAsyncThunk(
    'refreshTokenAction',
    async (payload: { token: string; refreshToken: string }, { rejectWithValue }) => {
        const axiosInstance = axios.create();
        const clientId = process.env.REACT_APP_CF_CLIENT_ID;
        const clientAccessToken = process.env.REACT_APP_CF_ACCESS_SECRET;
        const headers =
            clientId && clientAccessToken
                ? {
                      'CF-Access-Client-Id': clientId,
                      'CF-Access-Client-Secret': clientAccessToken,
                  }
                : {};
        try {
            return await axiosInstance.post<{ token: string }>(
                `${process.env.REACT_APP_BASE_URL}token/auth/refresh`,
                {
                    access_token: payload.token,
                    refresh_token: payload.refreshToken,
                },
                {
                    headers,
                },
            );
        } catch (error: any) {
            return rejectWithValue(error);
        }
    },
);

const initialState: AuthState = {
    loading: false,
    errorMessage: '',
    isAuth: false,
    user: undefined,
    token: '',
    isChatAuth: false,
};

const authReducer = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(registrationBy.fulfilled, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.errorMessage;
        });
        builder.addCase(registrationBy.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(loginBy.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loginBy.fulfilled, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.errorMessage;
            if (action.payload.data) {
                state.isAuth = true;
                state.user = action.payload.data.user;
            }
        });
        builder.addCase(loginBy.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload;
        });
        // builder.addCase(setUserChatInfo, (state) => {
        //     const token = getToken();
        //     if (token) {
        //         const { email } = jwt_decode<{ email: string }>(token);
        //         state.username = email.substring(0, email.lastIndexOf('@'));
        //     } else {
        //         state.username = '';
        //     }
        // });
        builder.addCase(logOutBy, (state) => {
            state.errorMessage = '';
            state.isAuth = false;
            state.user = null;
            deleteToken();
        });
        builder.addCase(resetErrorMessageBy, (state) => {
            state.loading = false;
            state.errorMessage = '';
        });
    },
});

export default authReducer.reducer;
