import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

interface RefState {
    languages: any;
    countries: any;
    timezones: any;
    categories: any;
    allRoles: any;
    skills: any;
    certifications: any;
    socialNetworks: any;
    audienceLevel: any;
    plans: any;
}

export const getRefData: any = createAsyncThunk('getRefData', async () => {
    const languages = await axios.get('refdata/languages');
    const countries = await axios.get('refdata/countries');
    const timezones = await axios.get('refdata/timezones?sort=offset,ASC');
    const categories = await axios.get('refdata/categories');
    const skills = await axios.get('refdata/skills');
    const certifications = await axios.get('refdata/certifications');
    const socialNetworks = await axios.get('refdata/social-networks');
    const allRoles = await axios.get('admin/roles');
    const audienceLevel = await axios.get('refdata/audience-level');
    const plans = await axios.get('user/subscriptions/plans');

    return {
        languages: languages.data,
        countries: countries.data,
        timezones: timezones.data.map((r: any) => ({ key: r.abbr_value, name: r.text })),
        categories: categories.data,
        allRoles: allRoles.data,
        skills: skills.data,
        certifications: certifications.data,
        socialNetworks: socialNetworks.data,
        audienceLevel: audienceLevel.data,
        plans: plans.data,
    };
});

export const createSkill = createAsyncThunk('createSkill', async (data: { key: string; name: string }) => {
    const result = await axios.post('refdata/skills', data);
    console.log('createSkill!', result);
    return result.status;
});

export const createCertification = createAsyncThunk(
    'createCertification',
    async (data: { key: string; name: string }) => {
        const result = await axios.post('refdata/certifications', data);
        console.log('create certifications!', result);
        return result.status;
    },
);

const initialState: RefState = {
    languages: [],
    countries: [],
    timezones: [],
    categories: [],
    allRoles: [],
    skills: [],
    certifications: [],
    socialNetworks: [],
    audienceLevel: [],
    plans: [],
};

const refDataReducer = createSlice({
    name: 'refData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRefData.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export default refDataReducer.reducer;
