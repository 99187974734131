import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCertificateById = createAsyncThunk('getCertificateById', async (id: string) => {
    const certificates = await axios.get(`user/certificates/${id}`);
    return certificates.data;
});

export const getOnlyUserEmails = createAsyncThunk('getOnlyUserEmails', async () => {
    const users = await axios.get('admin/users?fields=id,email,first_name,last_name');
    return users.data;
});

export const getShortEvents = createAsyncThunk('getShortEvents', async () => {
    const events = await axios.get('events/events?fields=id,slug&filter=is_published||$eq||true');
    return events.data;
});

export const getShortBootcamps = createAsyncThunk('getShortBootcamps', async () => {
    const bootcamps = await axios.get('events/bootcamps?fields=id,slug&filter=is_published||$eq||true');
    return bootcamps.data;
});

export const createCertificate: any = createAsyncThunk('createCertificate', async (data: CreateCertificateData) => {
    const response = await axios.post('user/certificates/', data);
    return response.data;
});

export const deleteCertificate: any = createAsyncThunk('deleteCertificate', async (uuid: string) => {
    const response = await axios.delete(`user/certificates/${uuid}`);
    return response.data;
});

export const updateCertificate: any = createAsyncThunk('updateCertificate', async (data: UpdateCertificateData) => {
    const response = await axios.patch(`user/certificates/${data.uuid}`, data.details);
    return response.data;
});

export type CreateCertificateData = {
    user_id: number;
    bootcamp_id: number | null;
    event_id: number | null;
    event_type: string;
    first_name: string;
    last_name: string;
};

export type UpdateCertificateData = {
    uuid: string;
    details: CreateCertificateData;
};

export type CertificateDetails = {
    id: string;
    avatar: string;
    user_id: number;
    bootcamp_id: number;
    event_id: number;
    event_type: string;
    first_name: string;
    last_name: string;
    created_date: Date;
    updated_date: Date;
};

export type ShortUsers = {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
};
export type ShortEvents = {
    id: number;
    slug: string;
};

interface CertificateDetailsState {
    certificate: CertificateDetails;
    users: ShortUsers[];
    events: ShortEvents[];
    bootcamps: ShortEvents[];
    isLoading: boolean;
    isError: boolean;
    resultText: string;
}

const setPendingState = (state: CertificateDetailsState) => {
    state.isLoading = true;
    state.isError = false;
    state.resultText = '';
    return state;
};

const initialState: CertificateDetailsState = {
    certificate: {
        id: '',
        avatar: '',
        user_id: 0,
        bootcamp_id: 0,
        event_id: 0,
        event_type: '',
        first_name: '',
        last_name: '',
        created_date: new Date(),
        updated_date: new Date(),
    },
    users: [],
    events: [],
    bootcamps: [],
    isLoading: false,
    isError: false,
    resultText: '',
};

const certificateDetailsReducer = createSlice({
    name: 'certificateDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCertificateById.pending, (state) => {
            return setPendingState(state);
        });
        builder.addCase(getCertificateById.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.resultText = action.error.message || 'Certificate fetch error';
            return state;
        });
        builder.addCase(getCertificateById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.resultText = 'Certificate fetch success';
            state.certificate = action.payload;
            return state;
        });

        builder.addCase(getOnlyUserEmails.pending, (state) => {
            return setPendingState(state);
        });
        builder.addCase(getOnlyUserEmails.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.resultText = action.error.message || 'Users fetch error';
            return state;
        });
        builder.addCase(getOnlyUserEmails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.resultText = 'Users fetch success';
            state.users = action.payload.data;
            // console.log(state.users);
            return state;
        });

        builder.addCase(getShortEvents.pending, (state) => {
            return setPendingState(state);
        });
        builder.addCase(getShortEvents.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.resultText = action.error.message || 'Events fetch error';
            return state;
        });
        builder.addCase(getShortEvents.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.resultText = 'Events fetch success';
            state.events = action.payload.data;
            return state;
        });

        builder.addCase(getShortBootcamps.pending, (state) => {
            return setPendingState(state);
        });
        builder.addCase(getShortBootcamps.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.resultText = action.error.message || 'Bootcamps fetch error';
            return state;
        });
        builder.addCase(getShortBootcamps.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.resultText = 'Bootcamps fetch success';
            state.bootcamps = action.payload.data;
            return state;
        });
    },
});

export default certificateDetailsReducer.reducer;
