import { createAction, createSlice } from '@reduxjs/toolkit';

export const closeSnackbar: any = createAction('closeSnackbar');
export const showSnackbar: any = createAction('showSnackbar');

interface SnackbarState {
    opened: boolean;
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
}

const initialState: SnackbarState = {
    opened: false,
    message: '',
    severity: 'success',
};

const allUsersReducer = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(showSnackbar, (state, action) => {
            return {
                opened: true,
                message: action.payload.message,
                severity: action.payload.severity,
            };
        });
        builder.addCase(closeSnackbar, (state, action) => {
            return {
                ...initialState,
            };
        });
    },
});

export default allUsersReducer.reducer;
