import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

interface InstructorFeedbackStats {
    feedbacksCount: number;
    eventsCount: number;
    instructorId: number;
    instructorFirstName: string;
    instructorLastName: string;
    instructorEmail: string;
}

interface InstructorFeedbacks {
    feedback: string | null;
    rating: number;
    intructor_rating: number;
    eventName: string;
    slug: string;
    type: 'Webinar' | 'Bootcamp';
}

interface InstructorFeedbackState {
    instructorsStats: InstructorFeedbackStats[];
    instructorFeedbacks: InstructorFeedbacks[];
}

const initialState: InstructorFeedbackState = {
    instructorsStats: [],
    instructorFeedbacks: [],
};

export const getFeedbackStats = createAsyncThunk('getFeedbackStats', async () => {
    const response = await axios.get('admin/feedback/stats');
    return response.data;
});

export const getFeedbacksByInstructorId = createAsyncThunk('getFeedbacksByInstructorId', async (id: string) => {
    const response = await axios.get(`admin/feedback/${id}`);
    return response.data;
});

const instructorFeedbackReducer = createSlice({
    name: 'instructorFeedbacks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFeedbackStats.fulfilled, (state, action) => {
            state.instructorsStats = action.payload;
            return state;
        });

        builder.addCase(getFeedbacksByInstructorId.fulfilled, (state, action) => {
            state.instructorFeedbacks = action.payload;
        });
    },
});

export default instructorFeedbackReducer.reducer;
