import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { EventsStatus, BootcampState } from './bootcampDetails/types';

export const getAllBootcamps: any = createAsyncThunk('getAllBootcamps', async () => {
    const bootcamps = await axios.get('events/bootcamps?join=plans');
    return bootcamps.data;
});

interface AllBootcampsState {
    published: BootcampState[];
    draft: BootcampState[];
    onReview: BootcampState[];
    errorMessage: string;
    successMessage: string;
    isLoading: boolean;
}

const initialState: AllBootcampsState = {
    published: [],
    draft: [],
    onReview: [],
    errorMessage: '',
    successMessage: '',
    isLoading: false,
};

const returnErrorState = (state: AllBootcampsState, action: any) => {
    state.isLoading = false;
    state.successMessage = '';
    state.errorMessage = action.error.message;
    return state;
};

const returnPendingState = (state: AllBootcampsState) => {
    state.isLoading = true;
    return state;
};

const returnSuccessState = (state: AllBootcampsState, action: any) => {
    state.published = action.payload.data.filter((boot: BootcampState) => boot.status >= EventsStatus.Published);
    state.draft = action.payload.data.filter((boot: BootcampState) => boot.status === EventsStatus.Draft);
    state.onReview = action.payload.data.filter((boot: BootcampState) => boot.status === EventsStatus.OnReview);
    state.isLoading = false;
    state.successMessage = 'Bootcamps fetched success';
    state.errorMessage = '';
    return state;
};

const allBootcampsReducer = createSlice({
    name: 'bootcamps',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllBootcamps.fulfilled, (state, action) => {
            return returnSuccessState(state, action);
        });
        builder.addCase(getAllBootcamps.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getAllBootcamps.rejected, (state, action) => {
            return returnErrorState(state, action);
        });
    },
});

export default allBootcampsReducer.reducer;
