import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export type CreateDomainData = {
    plan_expiration_date: string;
    domain_name: string;
};

export type UpdateDomainData = {
    id: string;
} & CreateDomainData;

export const getAllDomains = createAsyncThunk('getAllDomains', async () => {
    const domains = await axios.get('admin/domains');
    return domains.data;
});

export const getById = createAsyncThunk('getById', async (id: string) => {
    const response = await axios.get(`admin/domains/${id}`);
    return response.data;
});

export const getStatsById = createAsyncThunk('getStatsById', async (id: string) => {
    const response = await axios.get(`admin/domains/${id}/stats`);
    return response.data;
});

export const getUsersById = createAsyncThunk('getUsersById', async (id: string) => {
    const response = await axios.get(`admin/domains/${id}/users`);
    return response.data;
});

export const createDomain: any = createAsyncThunk('createDomain', async (data: CreateDomainData) => {
    await axios.post('admin/domains', data);
});

export const removeDomain: any = createAsyncThunk('removeDomain', async (id: string) => {
    await axios.delete(`admin/domains/${id}`);
});

export const updateDomain: any = createAsyncThunk('updateDomain', async (data: UpdateDomainData) => {
    await axios.patch(`admin/domains/${data.id}`, data);
});

export interface Domain {
    id: number;
    domain_name: string;
    plan_expiration_date: string;
    updated_date: string;
    created_date: string;
}

export interface DomainStats {
    usersCount: number;
    subsCount: number;
}

export interface DomainUser {
    userId: number;
    haveCorrporateSubscription: boolean;
    numberOfEnrolledSessions: number;
    userEmail: string;
    userName: string;
}
export interface DomainState {
    domains: Domain[];
    domainDetails: Domain;
    domainStats: DomainStats;
    domainUsers: DomainUser[];
}

const initialState: DomainState = {
    domains: [],
    domainDetails: {
        id: 0,
        domain_name: ``,
        plan_expiration_date: new Date().toISOString(),
        updated_date: new Date().toISOString(),
        created_date: new Date().toISOString(),
    },
    domainStats: {
        usersCount: 0,
        subsCount: 0,
    },
    domainUsers: [],
};
const domainsReducer = createSlice({
    name: 'domains',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllDomains.fulfilled, (state, action) => {
            state.domains = action.payload;
            return state;
        });

        builder.addCase(getById.fulfilled, (state, action) => {
            state.domainDetails = action.payload;
            return state;
        });

        builder.addCase(getStatsById.fulfilled, (state, action) => {
            state.domainStats = action.payload;
            return state;
        });

        builder.addCase(getUsersById.fulfilled, (state, action) => {
            state.domainUsers = action.payload;
        });
    },
});

export default domainsReducer.reducer;
