import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const trainingsUrl = process.env.REACT_APP_TRAININGS_URL;
export const getTrainings: any = createAsyncThunk('getTrainings', async () => {
    if (!trainingsUrl) {
        console.warn('The URL of the image is not specified in configuration file');
        return undefined;
    }
    const trainings = await axios.get(trainingsUrl);
    return trainings.data.bootcamps;
});

export const saveTrainings: any = createAsyncThunk('saveTrainings', async (trainings) => {
    await axios.put(`admin/trainings`, trainings);
});

interface TrainingsState {
    trainings: [];
    isTrainingsLoading: boolean;
}

const initialState: TrainingsState = {
    trainings: [],
    isTrainingsLoading: false,
};

const trainingsReducer = createSlice({
    name: 'trainings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTrainings.pending, (state) => {
            state.isTrainingsLoading = true;
        });
        builder.addCase(getTrainings.fulfilled, (state, action) => {
            state.trainings = action.payload;
            state.isTrainingsLoading = false;
            return state;
        });
    },
});

export default trainingsReducer.reducer;
