export const centsToDollars = (cents: number) => {
    return cents / 100;
};

export const dollarsToCents = (dollars: number) => {
    const compensation = dollars.toString();
    const [int] = compensation.split('.');
    return Number(
        parseFloat(dollars.toString())
            .toFixed(2)
            .replace('.', '')
            .padEnd(int.length === 1 ? 3 : 4, '0'),
    );
};
