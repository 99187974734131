import type { BootcampDetailsState } from '../types';

export const initialState: BootcampDetailsState = {
    bootcamp: {
        id: 0,
        name: '',
        description: '',
        language_id: '',
        number_of_seats: 0,
        audience_level_id: 0,
        categories: [],
        created_date: new Date(),
        objectives: '',
        prerequisites: '',
        format: '',
        instructor: {
            created_date: new Date(),
            first_name: '',
            last_name: '',
            slug: '',
            user: {
                email: '',
            },
            id: 0,
        },
        is_canceled: false,
        is_recommended: false,
        slug: '',
        syllabus: {},
        updated_date: new Date(),
        views: 0,
        what_skills_you_learn: '',
        what_students_be_provided: '',
        what_students_should_bring: '',
        who_should_attend: '',
        zoom_meeting_id: '',
        events: [],
        attendees: [],
        is_paid: false,
        certificate_issued: false,
        type: '',
        has_been_funded: false,
        instructor_id: 0,
        note_from_instructor: null,
        product_id: '',
        price_id: '',
        plans: [],
        sponsored: {
            id: 0,
            type: '0',
            company_name: '',
            company_url: '',
        },
        is_private: undefined,
        status: 0,
        compensation: null,
        is_compensation_done: false,
        clearing_date: null,
        course_price: 0,
        is_course: false,
    },
    isLoading: false,
    isError: false,
    resultText: '',
};
