import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

export const getAllUsers: any = createAsyncThunk('getAllUsers', async () => {
    const users = await axios.get('admin/users');

    return users.data;
});

export const getCtfdUsers: any = createAsyncThunk('getCtfdUsers', async () => {
    const response = await axios.get('user/ctfd/users');

    return response.data;
});

export const getProUsers: any = createAsyncThunk('getProUsers', async () => {
    const response = await axios.get('admin/users/proUsersStats');

    return response.data;
});

interface UsersList {
    data: any;
    count: number;
    total: number;
    page: number;
    pageCount: number;
}

interface CtfdUser {
    email: string;
    id: number;
    score: number;
    firstName: string;
    lastName: string;
}

interface ProUserStats {
    id: number;
    number_of_watched_recordings: number;
    number_of_enrolled_sessions: number;
    first_name: number;
    last_name: number;
    email: string;
    plan_type: string;
}

interface UsersState {
    users: UsersList;
    ctfdUsers: CtfdUser[];
    proUsers: ProUserStats[];
}

const initialState: UsersState = {
    users: {
        data: [],
        count: 0,
        total: 0,
        page: 0,
        pageCount: 0,
    },
    ctfdUsers: [],
    proUsers: [],
};

const allUsersReducer = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllUsers.fulfilled, (state, action) => {
            state.users = action.payload;
            return state;
        });

        builder.addCase(getCtfdUsers.fulfilled, (state, action) => {
            state.ctfdUsers = action.payload;
            return state;
        });

        builder.addCase(getProUsers.fulfilled, (state, action) => {
            state.proUsers = action.payload;
            return state;
        });
    },
});

export default allUsersReducer.reducer;
