import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

type BootcampLead = {
    id: number;
    user_id: number;
    bootcamp_id: number;
    user: {
        first_name: string;
        last_name: string;
        email: string;
    };
    created_date: Date;
};

export const getLeadsByBootcampId = createAsyncThunk('getLeadsByBootcampId', async (id: number) => {
    const leads = await axios.get(`admin/leads?s={"bootcamp_id":${id}}`);
    return leads.data;
});

const returnErrorState = (state: LeadsState, action: any) => {
    state.isLoading = false;
    state.successMessage = '';
    state.errorMessage = action.error.message;
    return state;
};

interface LeadsState {
    data: BootcampLead[];
    errorMessage: string;
    successMessage: string;
    isLoading: boolean;
}
const initialState: LeadsState = {
    data: [],
    errorMessage: '',
    successMessage: '',
    isLoading: false,
};

const returnSuccessState = (state: LeadsState, action: any) => {
    state.data = action.payload;
    state.isLoading = false;
    state.successMessage = 'Leads fetch success';
    state.errorMessage = '';
    return state;
};
const returnPendingState = (state: LeadsState) => {
    state.isLoading = true;
    return state;
};

const bootcampLeadsReducer = createSlice({
    name: 'bootcampLeads',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLeadsByBootcampId.fulfilled, (state, action) => {
            return returnSuccessState(state, action);
        });
        builder.addCase(getLeadsByBootcampId.pending, (state) => {
            return returnPendingState(state);
        });
        builder.addCase(getLeadsByBootcampId.rejected, (state, action) => {
            return returnErrorState(state, action);
        });
    },
});

export default bootcampLeadsReducer.reducer;
