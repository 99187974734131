import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getAllCertificates = createAsyncThunk('getAllCertificates', async () => {
    const certificates = await axios.get('user/certificates?join=user||email&join=bootcamp||slug&join=event||slug');
    return certificates.data;
});

type CertificatesPayload = {
    data: any;
    count: number;
    total: number;
    page: number;
    pageCount: number;
};

interface CertificatesState {
    payload: CertificatesPayload;
    isLoading: boolean;
    isError: boolean;
    resultText: string;
}

const initialState: CertificatesState = {
    payload: {
        data: [],
        count: 0,
        total: 0,
        page: 0,
        pageCount: 0,
    },
    isLoading: false,
    isError: false,
    resultText: '',
};

const setPendingState = (state: CertificatesState) => {
    state.isLoading = true;
    state.isError = false;
    state.resultText = '';
    return state;
};

const certificatesReducer = createSlice({
    name: 'certificates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllCertificates.pending, (state) => {
            return setPendingState(state);
        });
        builder.addCase(getAllCertificates.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.resultText = action.error.message || 'Certificates fetch error';
            return state;
        });
        builder.addCase(getAllCertificates.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.resultText = 'Certificates fetch success';
            state.payload = action.payload;
            return state;
        });
    },
});

export default certificatesReducer.reducer;
