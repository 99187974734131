import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const imageServerUrl = process.env.REACT_APP_S3_HOST;

export const getAllInstructors: any = createAsyncThunk('getAllInstructors', async () => {
    const instructors = await axios.get('user/instructors');
    return instructors.data;
});

export const getInstructorBySlug: any = createAsyncThunk('getInstructorBySlug', async (slug: string) => {
    const instructor = await axios.get(`user/instructors/${slug}`);
    return instructor.data;
});

export const updateInstructorBySlug: any = createAsyncThunk('updateInstructorBySlug', async (params: any) => {
    const response = await axios.patch(`user/instructors/${params.slug}`, params);
    return response.data;
});

export const allowPaidBootcamps: any = createAsyncThunk('allowPaidBootcamps', async (params: any) => {
    const action = params.is_allowed ? 'allow' : 'deny';
    const response = await axios.post(
        `payment-processor/stripe-processor/allow-paid-bootcamps?slug=${params.slug}&action=${action}`,
    );
    return response.data;
});

export const resetStripeAccount: any = createAsyncThunk('resetStripeAccount', async (slug: string) => {
    const response = await axios.post(
        `payment-processor/stripe-processor/instructor-stripe-account?slug=${slug}&action=reset`,
    );
    return response.data;
});

export const getEventsByInstructorSlug: any = createAsyncThunk('getEventsByInstructorSlug', async (slug: string) => {
    const response = await axios.get(`admin/events/${slug}`);
    return response.data;
});

export const getPayoutsByInstructorSlug: any = createAsyncThunk('getPayoutsByInstructorSlug', async (slug: string) => {
    const response = await axios.get(`admin/payouts/${slug}`);
    return response.data;
});

export const deleteInstructorProfileImage: any = createAsyncThunk(
    'deleteInstructorProfileImage',
    async (id: number) => {
        const response = await axios.delete(`user/avatars?id=${id}&type=Application`);
        return response.data;
    },
);

export const setInstructorProfileImage = createAsyncThunk(
    'setInstructorProfileImage',
    async (payload: { formData: FormData }, { rejectWithValue }) => {
        try {
            const response: any = {};
            if (!imageServerUrl) {
                console.warn('The URL of the image server is not specified in configuration file (.env)');
                return response;
            }
            const apiAnswer = await axios.post<{ userName: string; logo: { initial: string } }>(
                'user/avatars',
                payload.formData,
                {
                    responseType: 'text',
                    headers: { 'Content-Type': 'multipart/form-data' },
                },
            );
            const fileVersion = new Date().getTime();
            const avatarUrl = apiAnswer.data.logo.initial;
            if (avatarUrl.indexOf('https://') !== 0) {
                response.userProfileImageUrl = `${imageServerUrl}/${avatarUrl}?v=${fileVersion}`;
            } else {
                response.userProfileImageUrl = `${avatarUrl}?v=${fileVersion}`;
            }
            return response;
        } catch (error: any) {
            console.warn(error);
            if (!error.response) {
                return rejectWithValue(error);
            }
            if (error.response.status === 413) {
                return rejectWithValue({
                    statusCode: 413,
                    message: 'File is too big. Max files size should be less than 1 MB.',
                    error: 'Payload Too Large',
                });
            }
            return rejectWithValue(error.response.data);
        }
    },
);

export interface Instructor {
    id: number;
    created_date: Date;
    first_name: string;
    last_name: string;
    slug: string;
    user: {
        email: string;
    };
}

interface InstructorWithStats extends Instructor {
    totalEvents: number;
    totalPayouts: number;
    totalPendingCompensation: number;
}

interface InstructorState {
    instructors: InstructorWithStats[];
    eventsByInstructorId: any[];
    payoutsByInstructorId: any[];
}

const initialState: InstructorState = {
    instructors: [],
    eventsByInstructorId: [],
    payoutsByInstructorId: [],
};
const instructorReducer = createSlice({
    name: 'instructors',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllInstructors.fulfilled, (state, action) => {
            state.instructors = action.payload;
            return state;
        });

        builder.addCase(getEventsByInstructorSlug.fulfilled, (state, action) => {
            state.eventsByInstructorId = action.payload;
            return state;
        });

        builder.addCase(getPayoutsByInstructorSlug.fulfilled, (state, action) => {
            state.payoutsByInstructorId = action.payload;
            return state;
        });
    },
});

export default instructorReducer.reducer;
