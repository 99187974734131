import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setToken } from '../../utils/localStorage';

const useAppAuth0 = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        getTokens();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const getTokens = async () => {
        const token = await getAccessTokenSilently();
        setToken(token);
    };
};

export default useAppAuth0;
