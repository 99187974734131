import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import Header from '../../components/Header';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
    },
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formItem: {
        width: '350px',
        marginTop: theme.spacing(1),
    },
}));

const Login = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const classes = useStyles();
    const history = useHistory();

    // useEffect(() => {
    //     resetErrorMessageBy();
    // }, [resetErrorMessageBy]);

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/admin-panel');
        }
    }, [history, isAuthenticated]);

    return (
        <div className={classes.root}>
            <Header />
            <Paper className={classes.paper}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <div className={classes.paper}>
                        <Typography>Login to Your Account</Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.formItem}
                            onClick={() => loginWithRedirect()}
                        >
                            Sign In
                        </Button>
                    </div>
                </Grid>
            </Paper>
        </div>
    );
};

export default Login;
