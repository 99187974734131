/* eslint-disable camelcase */
import { Instructor } from '../../instructors';
import { Attendee } from '../../attendees';
import { SponsoredEvent } from '../../webinars';

export enum EventsStatus {
    Draft = 1,
    OnReview = 2,
    Rejected = 3,
    Published = 4,
    Ongoing = 5,
    Archived = 6,
}

export type BootcampPaymentDetailsState = {
    slug: string;
    is_paid: boolean;
    price?: number;
    plans: number[];
    compensation: string | number | null;
    clearing_date: Date | string | null;
};

export type BootcampEvent = {
    name: string;
    format: string;
    start_datetime: Date | string;
    duration: number;
    id?: number | string;
};

export interface BootcampState {
    id: number;
    name: string;
    description: string;
    language_id: string;
    number_of_seats: number;
    audience_level_id: number;
    categories: string[];
    created_date: Date;
    objectives: string;
    prerequisites: string;
    format: string;
    instructor: Instructor;
    is_canceled: boolean;
    is_recommended: boolean;
    price?: number;
    slug: string;
    syllabus: any;
    updated_date: Date;
    views: number;
    what_skills_you_learn: string;
    what_students_be_provided: string;
    what_students_should_bring: string;
    who_should_attend: string;
    zoom_meeting_id: string;
    events: BootcampEvent[];
    attendees: Attendee[];
    certificate_issued: boolean;
    has_been_funded: boolean;
    instructor_id: number;
    is_paid: boolean;
    note_from_instructor: string | null;
    type: string;
    product_id: string;
    price_id: string;
    plans: number[];
    sponsored: SponsoredEvent;
    is_private?: boolean | undefined;
    status: EventsStatus;
    compensation: null | number;
    is_compensation_done: boolean;
    clearing_date: string | null;
    course_price: number;
    is_course: boolean;
}

export interface BootcampDetailsState {
    bootcamp: BootcampState;
    isLoading: boolean;
    isError: boolean;
    resultText: string;
}
