import React from 'react';

import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import useSnackbar from '../features/hooks/useSnackbar';

const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ConnectedSnackbar = () => {
    const { opened, message, severity, closeSnackbar } = useSnackbar();
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={opened}
            onClose={closeSnackbar}
            message={message}
            autoHideDuration={7000}
        >
            <Alert onClose={closeSnackbar} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default ConnectedSnackbar;
