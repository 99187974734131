import axios from 'axios';
import { getRefreshToken, getToken, setToken } from '../../utils/localStorage';
import { store } from '../store/store';
import { isTruthy } from '../../shared/utils/type-guards';
import { logOutBy, refreshTokenAction } from '../../features/store/auth';

const httpConfig = () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios.defaults.responseType = 'json';

    axios.interceptors.request.use((request) => {
        if (request.method === 'post' && !request.headers['Content-Type']) {
            request.headers['Content-Type'] = 'application/json';
        }

        if (process.env.REACT_APP_TRAININGS_URL && request.url?.includes(process.env.REACT_APP_TRAININGS_URL)) {
            return request;
        }

        const clientId = process.env.REACT_APP_CF_CLIENT_ID;
        const clientAccessToken = process.env.REACT_APP_CF_ACCESS_SECRET;

        if (clientId && clientAccessToken) {
            request.headers['CF-Access-Client-Id'] = clientId;
            request.headers['CF-Access-Client-Secret'] = clientAccessToken;
        }

        const token = getToken();
        if (token) {
            request.headers.Authorization = `Bearer ${token}`;
        }
        return request;
    });

    axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            const token = getToken();
            const refreshToken = getRefreshToken();
            if (isTruthy(token) && isTruthy(refreshToken) && error.response.status === 401) {
                try {
                    const { data } = await store.dispatch(refreshTokenAction({ token, refreshToken })).unwrap();
                    setToken(data.token);

                    return axios(error.config);
                } catch (err) {
                    store.dispatch(logOutBy());
                    console.warn('Can not refresh token', err);
                }
            }

            if (error.response.status === 401) {
                store.dispatch(logOutBy());
            }

            return Promise.reject(error);
        },
    );
};
export default httpConfig;
